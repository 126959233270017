/*!
 * Start Bootstrap - Clean Blog v3.3.7+1 (http://startbootstrap.com/template-overviews/clean-blog)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

@import url('https://fonts.googleapis.com/css2?family=Palanquin&family=Palanquin+Dark:wght@700&display=swap');

body {
  font-family: 'Palanquin', sans-serif;
  font-size: 20px;
  color: #2c3e50;
  background: #ecf0f1;
}
p {
  line-height: 1.5;
  margin: 30px 0;
}
p a {
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Palanquin Dark', sans-serif;
  font-weight: 700;
}
a {
  color: #e74c3c;
}
a:hover,
a:focus {
  color: #34495e;
}
a img:hover,
a img:focus {
  cursor: zoom-in;
}
blockquote {
  color: #777777;
  font-style: italic;
}
hr.small {
  max-width: 100px;
  margin: 15px auto;
  border-width: 4px;
  border-color: white;
}

/* Snippet from CSS Tricks:
https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.navbar-collapse.in {
  overflow-y: hidden;
}

.navbar-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  font-family: 'Palanquin Dark', sans-serif;
  background-color: #2c3e50;
  border-color: #2c3e50;
  align-content: center;
}
.navbar-custom .navbar-brand {
  font-weight: 700;
  color: #FFF;
}
.navbar-custom .navbar-brand:hover {
  color: #ecf0f1;
}
.navbar-custom .navbar-header .navbar-toggle {
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
}
.navbar-custom .navbar-header .navbar-toggle:hover,
.navbar-custom .navbar-header .navbar-toggle:focus {
  background-color: #7f8c8d;
  color: #2c3e50;
}
.navbar-custom .nav-link {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #FFF;
}
ul.main-nav {
  list-style-type: none;
  padding: 20px 0 !important;
  margin-bottom: 0px;
}
.navbar-custom .nav li a:hover {
  color: #ecf0f1;
}
@media only screen and (min-width: 768px) {
  .navbar-custom {
    background: transparent;
    border-bottom: 1px solid transparent;
  }
  .navbar-custom .navbar-brand {
    color: white;
    padding: 20px;
  }
  .navbar-custom .navbar-brand:hover,
  .navbar-custom .navbar-brand:focus {
    color: rgba(255, 255, 255, 0.8);
  }
  .navbar-custom .nav li a {
    color: white;
    padding: 20px;
  }
  .navbar-custom .nav li a:hover,
  .navbar-custom .nav li a:focus {
    color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1170px) {
  .navbar-custom {
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .navbar-custom.is-fixed {
    /* when the user scrolls down, we hide the header right above the viewport */
    position: fixed;
    top: -61px;
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid #f2f2f2;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  .navbar-custom.is-fixed .navbar-brand {
    color: #2c3e50;
  }
  .navbar-custom.is-fixed .navbar-brand:hover,
  .navbar-custom.is-fixed .navbar-brand:focus {
    color: #0085A1;
  }
  .navbar-custom.is-fixed .nav li a {
    color: #2c3e50;
  }
  .navbar-custom.is-fixed .nav li a:hover,
  .navbar-custom.is-fixed .nav li a:focus {
    color: #0085A1;
  }
  .navbar-custom.is-visible {
    /* if the user changes the scrolling direction, we show the header */
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.intro-header {
  margin-bottom: 50px;
}

.color-red,
.color-green,
.color-purple {
  transition: background ease-in-out 0.5s;
}

.color-red {
  background-color: #e74c3c;
}

.color-green {
  background-color: #16a085;
}

.color-purple {
  background-color: #8e44ad;
}

.link-color-red,
.link-color-green,
.link-color-purple {
  transition: color ease-in-out 0.5s;
}

.link-color-red {
  color: #e74c3c;
}

.link-color-green {
  color: #16a085;
}

.link-color-purple {
  color: #8e44ad;
}

.intro-header .site-heading,
.intro-header .post-heading,
.intro-header .page-heading {
  padding: 100px 0 50px;
  color: white;
}
@media only screen and (min-width: 768px) {
  .intro-header .site-heading,
  .intro-header .post-heading,
  .intro-header .page-heading {
    padding: 150px 0;
  }
}
.intro-header .site-heading,
.intro-header .page-heading {
  text-align: center;
  padding-bottom: 100px;
}
.intro-header .site-heading h1,
.intro-header .page-heading h1 {
  margin-top: 0;
  font-size: 50px;
}
.intro-header .site-heading .subheading,
.intro-header .page-heading .subheading {
  font-size: 24px;
  line-height: 1.1;
  display: block;
  font-family: 'Palanquin', sans-serif;
  font-weight: 400;
  margin: 10px 0 0;
}
@media only screen and (min-width: 768px) {
  .intro-header .site-heading h1,
  .intro-header .page-heading h1 {
    font-size: 80px;
  }
}

.intro-header .post-heading h1 {
  font-size: 35px;
}
.intro-header .post-heading .subheading,
.intro-header .post-heading .meta {
  line-height: 1.1;
  display: block;
}
.intro-header .post-heading .subheading {
  font-family: 'Palanquin', sans-serif;
  font-size: 24px;
  margin: 10px 0 30px;
  font-weight: 700;
}
.intro-header .post-heading .meta {
  font-family: 'Palanquin Dark', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
}
.intro-header .post-heading .meta a {
  color: white;
}
@media only screen and (min-width: 768px) {
  .intro-header .post-heading h1 {
    font-size: 55px;
  }
  .intro-header .post-heading .subheading {
    font-size: 30px;
  }
}

.site-heading {
  margin-bottom: 50px;
}

.feature {
  padding-bottom: 10rem;
  padding-top: 7em;
  background: #FFF;
  margin: 0 auto;
  width: 90%;
  margin-top: -150px;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (min-width: 992px) {
  .feature {
    padding-left: 0px;
    padding-right: 0px;
    width: 70%;
  }
}

.main {
  background: #ecf0f1;
}

#getAnother,
#twitterButton {
  padding: 15px 25px;
  border-radius: 0;
  color: #FFF;
  border: none;
  margin-bottom: 10px;
  margin-top: 10px;
}
#getAnother:hover,
#getAnother:focus,
#twitterButton:hover,
#twitterButton:focus {
  color: white;
  background-color: #34495e;
}
#twitterButton {
  margin-top: 10px;
}

#twitterButton a{
  text-decoration: none;
  color: white;
}

@media only screen and (min-width: 523px) {
  #twitterButton {
    margin-top: 0px;
  }
}

.design-brief p {
  margin: 1rem 0;
}

.design-brief > a {
  color: #333333;
}
.design-brief > a:hover,
.design-brief > a:focus {
  text-decoration: none;
  color: #2980b9;
}
.design-brief > a > .brief-title {
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.design-brief > a > .brief-subtitle {
  margin: 0;
  font-weight: 300;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .design-brief > a > .brief-title {
    font-size: 36px;
  }
}

.deliverables {
  list-style: none;
}

.deliverables li:before {
    font-family: 'FontAwesome';
    content: '\f00c';
    margin:0 5px 0 -27px;
}

.section-heading {
  font-size: 36px;
  margin-top: 60px;
  font-weight: 700;
}
.caption {
  text-align: center;
  font-size: 14px;
  padding: 10px;
  font-style: italic;
  margin: 0;
  display: block;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
footer {
  padding: 50px 0 65px;
}
footer .list-inline {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}
footer .copyright {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
}

::-moz-selection {
  color: white;
  text-shadow: none;
  background: #0085A1;
}
::selection {
  color: white;
  text-shadow: none;
  background: #0085A1;
}
img::selection {
  color: white;
  background: transparent;
}
img::-moz-selection {
  color: white;
  background: transparent;
}
body {
  -webkit-tap-highlight-color: #2980b9;
}
